import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

import { useFloatingLabelProps } from '~/react/contexts/internal/floating-label/'

/**
 * @deprecated
 *
 * Use the FloatingLabelProps instead
 */
export type ListboxFloatingLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef>

const ListboxFloatingLabelInternal = renderWithRef<MinNativeRef, ListboxFloatingLabelProps>('ListboxFloatingLabel', null, ({ children, ...props }, ref) => {
  const labelProps = useFloatingLabelProps()

  return (
    <RenderComp root="label" classNames={['swan-listbox-floating-label']} forwardedRef={ref} props={{ ...props, ...labelProps }}>
      {children}
    </RenderComp>
  )
})

/**
 * @deprecated
 * Use the generic component FloatingLabel instead
 *
 * @subcomponent Listbox
 */
export const ListboxFloatingLabel = deprecatedComponent({
  description: 'Use the generic component FloatingLabel instead',
  displayName: 'ListboxFloatingLabel',
  root: ListboxFloatingLabelInternal,
})
