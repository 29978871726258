import { AriaListBoxOptions } from '@react-aria/listbox'
import { useMenuTrigger } from '@react-aria/menu'
import { useListState } from '@react-stately/list'
import { MenuTriggerState, useMenuTriggerState } from '@react-stately/menu'
import { ReactNode, useMemo, useRef } from 'react'

import { SwanPopoverContextProvider, SwanPopoverContextValue, useSwanPopover } from '~/react/components/popover'

import { useCollectionContext } from '~/react/contexts/internal/collection'
import { useModeExtractor } from '~/react/hooks/use-mode-extractor'

import { SwanListboxButtonContext, SwanListboxButtonContextValue } from './listbox-button.context'
import { SwanListboxListContext, SwanListboxSelectionProps, useListboxList } from './listbox-list.context'

export type SwanListboxContextProviderProps = {
  children?: ReactNode
  fullWidth?: boolean | null
  skin: 'standard' | 'error'
  disabled?: boolean
  selectionProps?: SwanListboxSelectionProps
}

function SwanListboxContextProviderInner(props: SwanListboxContextProviderProps) {
  const { children, disabled, skin, selectionProps } = props

  const { triggerRef, triggerProps, overlayState } = useSwanPopover()

  const { menuTriggerProps, menuProps } = useMenuTrigger(
    {
      ...triggerProps,
      isDisabled: disabled,
      type: 'listbox',
    },
    overlayState as MenuTriggerState,
    triggerRef,
  )

  const listboxButtonContextValue = useMemo<SwanListboxButtonContextValue>(
    () => ({
      skin,
      triggerProps: menuTriggerProps,
      triggerDisabled: disabled ?? false,
    }),
    [menuTriggerProps, disabled, skin],
  )

  const { childItems, items, disabledKeys } = useCollectionContext()

  // tweak the selection props to remove disabled keys and adjust the onSelectionChange behavior
  const listProps = useMemo<AriaListBoxOptions<object>>(() => {
    const { onSelectionChange, ...rest } = selectionProps ?? {}
    return {
      ...(menuProps as AriaListBoxOptions<object>),
      ...rest,
      disabledKeys,
      onSelectionChange: key => {
        if (onSelectionChange != null) {
          onSelectionChange(key)
        }

        // close the popover when a single option is selected
        if (rest.selectionMode !== 'multiple') {
          overlayState.close()
        }
      },
    }
  }, [disabledKeys, menuProps, overlayState, selectionProps])

  const listboxRef = useRef<HTMLUListElement>(null)
  const listState = useListState({ ...listProps, items, children: childItems })
  const listContextValue = useListboxList(listProps, listboxRef, listState)

  return (
    <SwanListboxButtonContext.Provider value={listboxButtonContextValue}>
      <SwanListboxListContext.Provider value={listContextValue}>{children}</SwanListboxListContext.Provider>
    </SwanListboxButtonContext.Provider>
  )
}

export function SwanListboxContextProvider(props: SwanListboxContextProviderProps) {
  const menuTriggerState = useMenuTriggerState({})
  const popoverRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLElement>(null)
  const modes = useModeExtractor(triggerRef, menuTriggerState.isOpen)

  const popoverContext = useMemo<SwanPopoverContextValue>(
    () => ({
      popoverRef,
      triggerRef,
      overlayState: menuTriggerState,
      overlayProps: {},
      triggerProps: {},
      fullWidth: props.fullWidth,
      modes,
    }),
    [popoverRef, triggerRef, menuTriggerState, props.fullWidth, modes],
  )

  return (
    <SwanPopoverContextProvider value={popoverContext}>
      <SwanListboxContextProviderInner {...props} />
    </SwanPopoverContextProvider>
  )
}
