import PropTypes, { InferProps } from 'prop-types'
import warning from 'tiny-warning'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useControllableValue } from '~/react/hooks'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { AccordionContext, ExpandedCollapsibleMap } from './accordion.context'

export type CollapsibleId = string

export const SkinEnum = {
  STANDARD: 'standard',
  COLOR_SWATCHES: 'color-swatches',
}

const propTypes = {
  /**
   * The visual style of the Accordion.
   * Available options: "standard",  "color-swatches"
   *
   * @default standard
   */
  // TODO: currently we're not getting the correct type for skin in the bundle snapshot, we need to fix this when we replace the prop-types with typescript
  skin: PropTypes.oneOf([SkinEnum.STANDARD, SkinEnum.COLOR_SWATCHES] as const),
  /**
   * Specifies if the Accordion should have full bleed
   *
   * @default false
   */
  fullBleed: PropTypes.bool,
  /**
   * Specifies if only a single collapsible can be expanded at a time or not
   *
   * @default false
   */
  single: PropTypes.bool,
  /**
   * The semantic purpose of the Accordion
   * If you want to render a list you can set the  purpose to 'list' and the Accordion will render an <ol> element
   */
  purpose: PropTypes.oneOf(['list'] as const),
  /**
   * The map of collapsible IDs and their expanded state.
   */
  expandedCollapsibles: PropTypes.objectOf(PropTypes.bool.isRequired),
  /**
   * The callback function for handling expanded change events.
   */
  onRequestExpandedChange: PropTypes.func as PropTypes.Requireable<(collapsibleId: CollapsibleId, expanded: boolean) => void>,
  /**
   * The default map of collapsible IDs and their expanded state. \n This is ignored if expandedCollapsibles is provided
   */
  defaultExpandedCollapsibles: PropTypes.objectOf(PropTypes.bool.isRequired),
}

const propKeysToRemove = Object.keys(propTypes)

export type AccordionProps = CoreProps<JSX.IntrinsicElements['div' | 'ol'], MinNativeRef, InferProps<typeof propTypes>>

const displayName = 'Accordion'
export const Accordion = renderWithRef<MinNativeRef, AccordionProps>(displayName, propTypes, (props, ref) => {
  useComponentStylesLoaded(displayName, SWAN_STYLE_KEY_MAP.accordion)

  const {
    single = false,
    expandedCollapsibles: controlledExpandedCollapsibleMap,
    onRequestExpandedChange: controlledOnRequestExpandedChange,
    // if no initial state is provided, start with all collapsible closed
    defaultExpandedCollapsibles: defaultExpandedCollapsibleMap,
    children,
    purpose,
    skin = 'standard',
    fullBleed = false,
    ...rest
  } = props

  const classNames = new Set<string>(['swan-vanilla-ignore', 'swan-accordion'])
  if (skin === 'color-swatches') {
    classNames.add(`swan-accordion-skin-${skin}`)
  }
  if (fullBleed) classNames.add('swan-accordion-full-bleed')

  warning(
    // note that we check props.single rather than single; that's because we set a default value on single
    // so we use props.single so that we know if the true value was undefined before the default was applied
    !(controlledExpandedCollapsibleMap !== undefined && props.single !== undefined),
    'Since `expandedCollapsibles` is provided, `single` will be ignored. The values in `expandedCollapsibles` will be treated as the source of truth',
  )

  const [expandedCollapsibleMap, requestExpandedChange] = useControllableValue<ExpandedCollapsibleMap, [CollapsibleId, boolean]>({
    value: controlledExpandedCollapsibleMap || undefined,
    onChange: controlledOnRequestExpandedChange || undefined,
    defaultValue: defaultExpandedCollapsibleMap || {},
    defaultOnChange: (setUncontrolledExpandedCollapsibles, ...onChangeArgs) => {
      const [collapsibleId, expanded] = onChangeArgs
      setUncontrolledExpandedCollapsibles(prevUncontrolledExpandedCollapsibles => ({
        ...(!single ? prevUncontrolledExpandedCollapsibles : {}),
        [collapsibleId]: expanded,
      }))
    },
  })

  return (
    <AccordionContext.Provider value={{ expandedCollapsibleMap, requestExpandedChange, purpose }}>
      <RenderComp
        root="div"
        forwardedRef={ref}
        propKeysToRemove={propKeysToRemove}
        classNames={classNames}
        props={{
          ...rest,
          as: purpose === 'list' ? props.as || props.component || 'ol' : props.as,
        }}
      >
        {children}
      </RenderComp>
    </AccordionContext.Provider>
  )
})
