import PropTypes, { InferProps } from 'prop-types'

import { tokens } from '~/core/tokens'
import { SpacePropType, StyleSpace } from '~/core/types/swan-style.types'
import { spaceTokensMap } from '~/core/utilities/swan-style.utils'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Decide whether or not to allow sticky Column.
   * @default false
   */
  sticky: PropTypes.bool,
  /**
   * Adds a vertical gap between wrapping columns.
   * `matchGutter` can be used to match the standard horizontal gap (note: does not work on 'tight' gutters) built in to grid.
   */
  verticalGap: PropTypes.oneOf([...SpacePropType, 'matchGutter'] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type RowProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

const gapMap: Record<StyleSpace | 'matchGutter', string> = {
  ...spaceTokensMap,
  // This doesn't support the tight gutter, but that's okay for now
  matchGutter: `calc(${tokens.SwanSemSpaceGutterStandard} * 2)`,
}

/**
 * @subcomponent GridContainer
 */
export const Row = renderWithRef<MinNativeRef, RowProps>('Row', null, ({ children, sticky = false, style, verticalGap, ...props }, ref) => {
  const classNames = ['swan-row']
  if (sticky) classNames.push('swan-row-sticky')

  const processedStyles = {
    ...style,
    rowGap: verticalGap != null ? gapMap[verticalGap] : undefined, // use != instead of !== to handle `undefined`
  }

  return (
    <RenderComp root="div" forwardedRef={ref} props={{ ...props, style: processedStyles }} propKeysToRemove={propKeysToRemove} classNames={classNames}>
      {children}
    </RenderComp>
  )
})
