import PropTypes, { InferProps } from 'prop-types'
import {
  AriaAttributes,
  AriaRole,
  Children,
  cloneElement,
  ComponentProps,
  ComponentRef,
  CSSProperties,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import SlickCarousel, { Settings as SlickCarouselProps } from 'react-slick'
import warning from 'tiny-warning'

import { tokensRaw as tokens } from '~/core/index'
import { Responsive, StyleBreakpoints } from '~/core/types'
import { assignRefs, className, isNotProduction, processStyleProps } from '~/core/utilities'

import { Button } from '~/react/components/button'
import { CoreProps, deprecatedProp, filterProps, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { Icon, IconProps } from '~/react/components/icon'
import { VisuallyHidden } from '~/react/components/visually-hidden'

import { ScreenClassContext } from '~/react/contexts'
import { useId } from '~/react/hooks'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'
import { usePreventVerticalScroll } from '~/react/hooks/use-prevent-vertical-scroll'
import { conditionalPropType } from '~/react/prop-types'

import { usePromoBarDarkMode } from './carousel.hooks'

const hasDots = (props: { dots?: boolean; progressIndicator?: null | 'none' | 'dots' | 'dots-inset' }) =>
  props?.dots || props?.progressIndicator === 'dots' || props?.progressIndicator === 'dots-inset'

const propTypes = {
  /**
   * The visual style of the Carousel.
   * One of: "standard", "full", "promo-bar".
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'full', 'promo-bar'] as const),
  /**
   * Specifies the way that slide-progress is indicated to the user.
   * One of: "none", "dots", "dots-inset".
   *
   * @default none
   */
  progressIndicator: PropTypes.oneOf(['none', 'dots', 'dots-inset'] as const),
  /**
   * Specifies the alignment of the progress indicator.
   * One of: "left", "center".
   * @default center
   */
  progressIndicatorAlignment: PropTypes.oneOf(['center', 'left'] as const),
  /**
   * Whether to show a peek of the next slide.
   * @default false
   */
  peek: PropTypes.bool,
  /**
   * Whether to place gutters between the slides that match the grid's gutters.
   * @default false
   */
  gridGutters: PropTypes.bool,
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * What kind of gutters to place between slides.
   * @default standard
   */
  gridGuttersVariant: deprecatedProp(
    PropTypes.string,
    'The props has been deprecated, as it is rarely used and to maintain consistency with GridContainer while reducing UX debt.',
  ),
  /**
   * The vertical position of the arrows, as a CSS "top" property.
   */
  arrowVerticalPosition: PropTypes.string,
  /**
   * The vertical offset of the arrows, relative to its CSS "top" property.
   * @default 0px
   */
  arrowVerticalOffset: PropTypes.string,
  /**
   * Set accessible text for previous` button.
   */
  accessibleTextForPrevious: PropTypes.string.isRequired,
  /**
   * Set accessible text for next button.
   */
  accessibleTextForNext: PropTypes.string.isRequired,
  /**
   * Set accessible text for carousel dots.
   */
  accessibleTextForDots: conditionalPropType(hasDots, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.string.isRequired)),
  /**
   * The current slide of the carousel.
   */
  currentSlide: PropTypes.number,
  /**
   * Localized text to be used in the aria-live region.
   * Should utilise placeholders {activeSlides} and {totalSlides} e.g. "Slide {activeSlides} of {totalSlides}"
   * @default Slide {activeSlides} of {totalSlides}
   */
  accessibleTextForVisibleSlides: PropTypes.string, // TODO: this should be revisited to fix grammar when we have localization
  /**
   * Provides an accessible label for the carousel container
   */
  'aria-label': PropTypes.string, // TODO: v4 make either one or the other required (aria-label or aria-labelledby) for carousel container
  /**
   * References of the element that labels the carousel container.
   */
  'aria-labelledby': PropTypes.string, // TODO: v4 make either one or the other required (either aria-label or aria-labelledby) for carousel container
  /**
   * Localized label for assistive tech to identify carousel container element as a "carousel."
   * @default carousel
   */
  'aria-roledescription': PropTypes.string, // TODO: v4 remove the default value and make it require
}

/**
 * Remove autoplay until we can support a11y by designing and adding proper pause controls
 */
type NativeProps = Omit<ComponentProps<typeof SlickCarousel>, 'autoplay' | 'slidesToShow'> & {
  /**
   * The number of slides to show at a time.
   * @default 1
   */
  slidesToShow?: Responsive<ComponentProps<typeof SlickCarousel>['slidesToShow']>
  /**
   * ARIA role for the Carousel component.
   * @default region
   */
  role?: AriaRole
} & Pick<AriaAttributes, 'aria-label' | 'aria-labelledby' | 'aria-roledescription'>

type NativeRef = ComponentRef<typeof SlickCarousel>

type CustomProps = Omit<InferProps<typeof propTypes>, 'accessibleTextForDots'> & {
  /**
   * Localized Accessible text for carousel dots.
   */
  accessibleTextForDots?: string[]
  /**
   * Set a unique id to the carousel
   */
  id?: string
}

export type CarouselProps = CoreProps<NativeProps, NativeRef, PropsWithChildren<CustomProps>, NativeProps>

const CarouselButton: FC<JSX.IntrinsicElements['button'] & { accessibleText: string; iconType: IconProps['iconType'] }> = ({
  onClick,
  accessibleText,
  iconType,
  className,
  style,
}) => {
  const id = useId()
  /**
   * Disabling the control button based on the class `slick-disabled` applied by react-slick
   * https://stackoverflow.com/a/67641248
   */
  return (
    <Button buttonShape="round" onClick={onClick} disabled={className?.includes('slick-disabled')} className={className} style={style} aria-labelledby={id}>
      <VisuallyHidden id={id}>{accessibleText}</VisuallyHidden>
      <Icon alt="" iconType={iconType} />
    </Button>
  )
}

const propKeysToRemove: (keyof CarouselProps)[] = ['role', 'aria-label', 'aria-labelledby', 'aria-roledescription', ...(Object.keys(propTypes) as Array<keyof typeof propTypes>)]

const useSlidesToShow = (slidesToShow: CarouselProps['slidesToShow'] = 1) => {
  const currentScreenClass = useContext(ScreenClassContext)
  if (typeof slidesToShow === 'number') {
    return slidesToShow
  }

  let calculatedSlidesToShow = 1
  // log a warning if there's no screen class provider
  if (currentScreenClass === undefined) {
    if (isNotProduction()) {
      throw new Error(
        "A responsive value for `slidesToShow` may only be used inside of a ScreenClassProvider. Make sure that you've rendered a ScreenClassProvider above this component your tree.",
      )
    }

    return calculatedSlidesToShow
  }

  // treat the props as mobile first
  const mobileFirst: StyleBreakpoints[] = ['xs', 'sm', 'md', 'lg', 'xl']
  for (const breakpoint of mobileFirst) {
    calculatedSlidesToShow = slidesToShow[breakpoint as StyleBreakpoints] ?? calculatedSlidesToShow
    if (currentScreenClass === breakpoint) {
      return calculatedSlidesToShow
    }
  }

  return calculatedSlidesToShow
}

export const UninitializedCarousel = (props: { children: ReactNode; className?: string }) => {
  const { children, className } = props
  return (
    <div className={`swan-carousel swan-carousel-placeholder ${className}`}>
      <div className="slick-list">
        <div className="slick-track">
          {Children.map(children, slide => (
            <div className="slick-slide">
              <div>{slide}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const Carousel = renderWithRef<NativeRef, CarouselProps>('Carousel', propTypes, (props, ref) => {
  useComponentStylesLoaded('Carousel', SWAN_STYLE_KEY_MAP.carousel)

  const {
    as,
    component,
    skin = 'standard',
    progressIndicator = 'none',
    progressIndicatorAlignment = false,
    peek = false,
    gridGutters = false,
    gridGuttersVariant = 'standard',
    arrowVerticalPosition,
    arrowVerticalOffset = '0px',
    children,
    // we extract some of the slickCarouselProps in order to add necessary classes
    // the defaults that we set here should match the Slick documentation so that we don't confuse folks
    dots = false,
    arrows = true,
    variableWidth = false,
    centerMode = false,
    slidesToShow = 1,
    centerPadding,
    // Props for container div
    role = 'region',
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-roledescription': ariaRoleDescription,
    accessibleTextForPrevious,
    accessibleTextForNext,
    accessibleTextForDots,
    accessibleTextForVisibleSlides = 'Slide {activeSlides} of {totalSlides}',
    customPaging,
    currentSlide,
    initialSlide = currentSlide,
    id: customId,
  } = props
  const [isInitialized, setIsInitialized] = useState(skin === 'promo-bar')
  const id = useId(customId)
  const placeholderClass = `swan-carousel-placeholder-${id}`
  const carouselContainerRef = useRef<HTMLDivElement>(null)
  const carouselRef = useRef<SlickCarousel>()
  const combinedRef = assignRefs(ref, carouselRef)

  const dotsEnabled = dots || progressIndicator !== 'none' || !!customPaging

  const { forceDarkMode, beforeChange } = usePromoBarDarkMode(carouselRef, props)

  const responsiveSlidesToShow = useSlidesToShow(slidesToShow)

  const finalProps: Partial<SlickCarouselProps> = {
    prevArrow: <CarouselButton accessibleText={accessibleTextForPrevious} iconType="chevronLeft" />,
    nextArrow: <CarouselButton accessibleText={accessibleTextForNext} iconType="chevronRight" />,
    arrows,
    centerMode: !!(centerMode || peek),
    dots: dotsEnabled,
    customPaging: dotsEnabled
      ? customPaging ||
        ((index: number): JSX.Element => {
          const content = Array.isArray(accessibleTextForDots) && accessibleTextForDots[index] != undefined ? accessibleTextForDots[index] : index + 1
          return <button>{content}</button>
        })
      : undefined,
    appendDots: dotsEnabled
      ? (dots: ReactNode): JSX.Element => {
          return (
            <ul>
              {Children.map(dots, dot =>
                isValidElement(dot)
                  ? cloneElement(
                      dot,
                      {},
                      cloneElement(dot.props.children, {
                        'aria-pressed': dot.props.className === 'slick-active',
                      }),
                    )
                  : dot,
              )}
            </ul>
          )
        }
      : undefined,
    initialSlide: typeof initialSlide === 'number' ? initialSlide : peek && responsiveSlidesToShow > 1 ? 1 : 0,
    slidesToShow: responsiveSlidesToShow,
    variableWidth,
    autoplay: false,
    fade: props.fade ?? skin === 'promo-bar',
  }
  const finalPropsXS: Partial<SlickCarouselProps> = {
    arrows,
  }

  if (centerPadding !== undefined) {
    finalProps.centerPadding = centerPadding
    finalPropsXS.centerPadding = centerPadding
  }
  if (peek) {
    finalPropsXS.centerPadding = '8'
  }

  warning(dotsEnabled || arrows, 'At least one of dots or arrow must be enabled for a carousel')
  warning(as === undefined && component === undefined, 'Carousel does not support the `component` or `as` prop because it must render a third-party component')

  const classNameIter = processStyleProps({
    ...props,
    darkMode: forceDarkMode || props.darkMode,
  })
  const filteredProps = filterProps(props, propKeysToRemove, classNameIter)
  const styleClasses = className(filteredProps.className, 'swan-carousel', {
    [`swan-carousel-skin-${skin}`]: skin !== 'standard',
    'swan-carousel-dots': finalProps.dots,
    'swan-carousel-dots-left': finalProps.dots && progressIndicatorAlignment === 'left',
    'swan-carousel-dots-inset': progressIndicator === 'dots-inset',
    'swan-carousel-hide-arrows': !finalProps.arrows,
    'swan-carousel-variable-width': finalProps.variableWidth,
    'swan-carousel-grid-gutters': gridGutters,
    'swan-carousel-grid-gutters-tight': gridGutters && gridGuttersVariant === 'tight',
    ['swan-carousel-peek-non-focusable-' + responsiveSlidesToShow]: !!peek && responsiveSlidesToShow % 2 === 0,
    [placeholderClass]: !isInitialized,
  })

  const containerStyle = {
    '--swan-internal-carousel-placeholder-slides-to-show-derived': responsiveSlidesToShow || 1,
    '--swan-internal-carousel-placeholder-slides-to-show-xs': typeof slidesToShow === 'object' ? slidesToShow.xs : undefined,
    '--swan-internal-carousel-placeholder-slides-to-show-sm': typeof slidesToShow === 'object' ? slidesToShow.sm : undefined,
    '--swan-internal-carousel-placeholder-slides-to-show-md': typeof slidesToShow === 'object' ? slidesToShow.md : undefined,
    '--swan-internal-carousel-placeholder-slides-to-show-lg': typeof slidesToShow === 'object' ? slidesToShow.lg : undefined,
    '--swan-internal-carousel-placeholder-slides-to-show-xl': typeof slidesToShow === 'object' ? slidesToShow.xl : undefined,
  } as CSSProperties

  const carouselStyle = {
    '--swan-internal-carousel-arrow-top': arrowVerticalPosition,
    '--swan-internal-carousel-arrow-vertical-offset': arrowVerticalOffset,
  } as CSSProperties

  usePreventVerticalScroll(carouselContainerRef)

  // Store the current Slick slide index which will update when the carousel changes in the beforeChange function
  const [currentSlickSlide, setCurrentSlickSlide] = useState<number | null>(null)
  const totalSlideCount = Children.toArray(children).length

  // Determine the activeSlides that are currently visible
  const activeSlidesText = useMemo(() => {
    const activeSlides: number[] = []
    if (currentSlickSlide === null) return null
    // Add one as it is 0-indexed and we want to show 1-indexed for the text
    let currentSlideCount = currentSlickSlide + 1
    // For each slide that is visible, add it to the newCurrentSlides array
    for (let i = 0; i < responsiveSlidesToShow; i++) {
      activeSlides.push(currentSlideCount)
      // If the currentSlideCount is equal to the totalSlideCount, we need to reset the counter to the first slide
      // Otherwise increase count
      if (currentSlideCount === totalSlideCount) {
        currentSlideCount = 1
      } else {
        currentSlideCount++
      }
    }
    return accessibleTextForVisibleSlides!.replace('{activeSlides}', activeSlides.join(', ')).replace('{totalSlides}', totalSlideCount.toString())
  }, [currentSlickSlide, totalSlideCount, responsiveSlidesToShow, accessibleTextForVisibleSlides])

  useEffect(() => {
    setIsInitialized(true)
  }, [])

  useEffect(() => {
    if (typeof currentSlide === 'number' && carouselRef.current) {
      carouselRef.current.slickGoTo(currentSlide)
    }
  }, [currentSlide])

  return (
    <div
      className="swan-carousel-container"
      ref={carouselContainerRef}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-roledescription={ariaRoleDescription || 'carousel'}
      role={role}
      style={containerStyle}
    >
      {isInitialized ? (
        <SlickCarousel
          responsive={[
            {
              breakpoint: parseInt(tokens.SwanBaseBreakpointXsEnd, 10),
              settings: finalPropsXS,
            },
          ]}
          {...filteredProps}
          {...finalProps}
          ref={combinedRef}
          className={styleClasses}
          /* Slick Carousel types don't include children or style, which React 18 requires to be explicit, so this is a hack */
          {...({ children, style: carouselStyle } as SlickCarouselProps)}
          // Used beforeChange here because while afterChange works, it doesn't update when testing because of the Slick delays
          beforeChange={(current, next) => {
            // This calls our beforeChange function from above, when we update to use :has css selector we can remove this
            beforeChange(current, next)
            setCurrentSlickSlide(next)
          }}
        />
      ) : (
        <UninitializedCarousel className={styleClasses}>{children}</UninitializedCarousel>
      )}
      <div data-testid="carousel-aria-live" className="swan-carousel-current-visible-slides" aria-live="polite">
        {isInitialized ? activeSlidesText : null}
      </div>
    </div>
  )
})
