import { StyleBackgroundColor } from '~/core/types'

/**
 * @deprecated use `@vp/marketing-colors` instead
 * https://vistaprint.atlassian.net/wiki/spaces/DesignSystemTeam/pages/3762227160/Removing+our+old+brand+and+marketing+colours
 */
export const MARKETING_COLORS = {
  atlantic: '#16618c',
  black900: '#000000',
  caspian: '#204949',
  celtic: '#046240',
  darkGrey: '#5c5c5c',
  emerald: '#0a806b',
  graphite: '#313131',
  // Ensure parity with holiday-variables.scss
  holiday: '#9a0202',
  holidayBeige1: '#ede3cf',
  holidayGreen1: '#50af60',
  holidayGreen2: '#06462E',
  holidayRed1: '#8b0d13',
  holidayRed2: '#8b0d13',
  kingfisher: '#017eb4',
  mediumGreen: '#318643',
  mediumGrey: '#dfdfdf',
  opal: '#c0e6df',
  platinum: 'efefef',
  rio: '#99d3f3',
  rum: '#c94b2f',
  talavera: '#2b3b55',
  warmWhite: '#f6f6ec',
  white: '#ffffff',
} as const

/**
 * Specifies whether an element should have standard or dark mode (or either) enabled when using the specified color as its background color.
 * This is used most commonly with hero components
 */
export const BACKGROUND_WITH_MODE: Record<StyleBackgroundColor, { darkMode: boolean; standardMode: boolean }> = {
  standard: {
    standardMode: true,
    darkMode: true,
  },
  info: {
    standardMode: true,
    darkMode: true,
  },
  destructive: {
    standardMode: true,
    darkMode: true,
  },
  strong: {
    standardMode: true,
    darkMode: true,
  },
  accent: {
    standardMode: true,
    darkMode: true,
  },
  promo: {
    standardMode: true,
    darkMode: true,
  },
  help: {
    standardMode: true,
    darkMode: true,
  },
  success: {
    standardMode: true,
    darkMode: true,
  },
  warning: {
    standardMode: true,
    darkMode: true,
  },
  error: {
    standardMode: true,
    darkMode: true,
  },
  'warm-white': {
    standardMode: true,
    darkMode: false,
  },
  midnight: {
    standardMode: false,
    darkMode: true,
  },
  holiday: {
    standardMode: false,
    darkMode: true,
  },
  'holiday-beige-1': {
    standardMode: true,
    darkMode: false,
  },
  'holiday-green-1': {
    standardMode: true,
    darkMode: false,
  },
  'holiday-green-2': {
    standardMode: false,
    darkMode: true,
  },
  'holiday-red-1': {
    standardMode: false,
    darkMode: true,
  },
  'holiday-red-2': {
    standardMode: false,
    darkMode: true,
  },
  black: {
    standardMode: false,
    darkMode: true,
  },
  transparent: {
    standardMode: true,
    darkMode: true,
  },
}
