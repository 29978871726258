import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

import { useFloatingLabelProps } from '~/react/contexts/internal/floating-label/'

/**
 * @deprecated
 * Use the FloatingLabelProps instead
 */
export type ComboboxFloatingLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef>

const ComboboxFloatingLabelInternal = renderWithRef<MinNativeRef, ComboboxFloatingLabelProps>('ComboboxFloatingLabel', null, ({ children, ...props }, ref) => {
  const labelProps = useFloatingLabelProps()

  return (
    <RenderComp root="label" forwardedRef={ref} props={{ ...props, ...labelProps }}>
      {children}
    </RenderComp>
  )
})

/**
 *
 * @deprecated
 * Use the generic component FloatingLabel instead
 *
 * @subcomponent Combobox
 */
export const ComboboxFloatingLabel = deprecatedComponent({
  description: 'Use the generic component FloatingLabel instead',
  displayName: 'ComboboxFloatingLabel',
  root: ComboboxFloatingLabelInternal,
})
