import { InferProps } from 'prop-types'
import { HTMLAttributes } from 'react'

import { CoreProps, deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

import { FloatingLabelContextProvider } from '~/react/contexts/internal/floating-label'

import { Combobox, comboboxPropTypes } from './combobox.component'
import { SwanComboboxContextProviderProps } from './combobox.context'

type NativeProps = HTMLAttributes<HTMLDivElement>

/**
 * @deprecated
 * This is deprecated. Use the InputWithFloatingLabelProps instead
 */
export type ComboboxWithFloatingLabelProps = CoreProps<NativeProps, HTMLDivElement, InferProps<typeof comboboxPropTypes> & SwanComboboxContextProviderProps, NativeProps>

const ComboboxWithFloatingLabelInternal = renderWithRef<HTMLDivElement, ComboboxWithFloatingLabelProps>('Combobox', comboboxPropTypes, (props, ref) => {
  const { className, children } = props

  const classes = new Set<string>(['swan-combobox-with-floating-label', 'swan-input-with-floating-label'])
  if (className) classes.add(className)

  return (
    <FloatingLabelContextProvider>
      <RenderComp root={Combobox} forwardedRef={ref} props={props} classNames={classes}>
        {children}
      </RenderComp>
    </FloatingLabelContextProvider>
  )
})

/**
 * @deprecated
 * This is deprecated. Use the generic component InputWithFloatingLabel instead
 *
 * @subcomponent Combobox
 */
export const ComboboxWithFloatingLabel = deprecatedComponent({
  description: 'Use the generic component InputWithFloatingLabel instead',
  displayName: 'ComboboxWithFloatingLabel',
  root: ComboboxWithFloatingLabelInternal,
})
