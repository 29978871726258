import PropTypes, { InferProps } from 'prop-types'
import { HTMLAttributes, useCallback, useMemo, useRef } from 'react'

import { processStyleProps } from '~/core/utilities'

import { CoreProps, deprecatedProp, filterProps, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { CollectionContextProvider } from '~/react/contexts/internal/collection'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { ListboxSelectedValues, SwanListboxSelectionProps } from './listbox-list.context'
import { SwanListboxContextProvider } from './listbox.context'

const propTypes = {
  /**
   * The size (height) of the Listbox.
   * One of: "standard", "mini".
   *
   * @deprecated
   * This is deprecated without a replacement.
   *
   * @default standard
   */
  size: deprecatedProp(PropTypes.oneOf(['standard', 'mini'] as const), 'Sizing is now handled automatically by standardMode/compactMode'),
  /**
   * The visual style of the Listbox.
   * One of: "standard", "error".
   *
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'error'] as const),
  /**
   * Whether or not the Listbox should expand to fill the entire width of its container.
   *
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * Whether or not the Listbox is disabled.
   *
   * @default false
   */
  disabled: PropTypes.bool,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T = any> = {
  /**
   * @deprecated
   * Sizing is now handled automatically by standardMode/compactMode
   *
   * The size (height) of the Listbox.
   * One of: "standard", "mini".
   */
  size?: 'standard' | 'mini'

  /**
   * The visual style of the Listbox.
   * One of: "standard",  "error".
   */
  skin?: 'standard' | 'error'

  /**
   * Whether or not the Listbox should expand to fill the entire width of its container.
   */
  fullWidth?: boolean

  /**
   * Whether or not the Listbox is disabled.
   */
  disabled?: boolean

  /**
   * The selection mode of the Listbox.
   * One of: "single", "multiple".
   *
   * @default single
   */
  selectionMode?: 'single' | 'multiple'

  /**
   * The keys of the selected items in the Listbox.
   */
  selectedKeys?: 'all' | Set<string | number>

  /**
   * The keys of the disabled items in the Listbox.
   */
  disabledKeys?: Set<string | number>

  /**
   * Callback function that gets triggered when the selection changes.
   */
  onSelectionChange?: (selectedKeys: ListboxSelectedValues) => void

  /**
   * The value of the selected item in single selection mode (for backward compatibility).
   */
  value?: string | null

  /**
   * Callback function that gets triggered when the value changes (for backward compatibility).
   */
  onChange?: (value: string) => void

  /**
   * Optional collection of dynamic options. Use with a render function as a child of ListboxList/ComboboxList
   */
  items?: Iterable<T>
}

const propKeysToRemove = [...Object.keys(propTypes), 'selectionMode', 'selectedKeys', 'disabledKeys', 'onSelectionChange', 'onChange', 'value', 'items']

type NativeProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & Props

export type ListboxProps = CoreProps<NativeProps, HTMLDivElement, InferProps<typeof propTypes>, Props>

/*
 * A utility hook to convert all the more user friendly props into the context value
 */
const useSelectionProps = ({ selectionMode = 'single', selectedKeys, onSelectionChange, value, onChange }: Props) => {
  // warn (only once) if using the legacy props with multiple selection mode
  const warned = useRef<boolean>(false)
  if (selectionMode !== 'single' && (onChange || value) && !warned.current) {
    console.warn('Listbox - Do not use "onChange" or "value" props with multiple selection mode. Only one item will be selected')
    warned.current = true
  }

  const onSelectedChanged = useCallback(
    (keys: ListboxSelectedValues) => {
      if (onSelectionChange) onSelectionChange(keys)

      if (onChange && selectionMode === 'single' && keys !== 'all') {
        onChange([...keys][0] as string)
      }
    },
    [onChange, onSelectionChange, selectionMode],
  )

  return useMemo<SwanListboxSelectionProps>(
    () => ({
      selectionMode: selectionMode,
      selectedKeys: value ? new Set([value]) : selectedKeys,
      onSelectionChange: onSelectedChanged,
    }),
    [selectionMode, value, selectedKeys, onSelectedChanged],
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Listbox = renderWithRef<HTMLDivElement, ListboxProps>('Listbox', propTypes, (props, ref) => {
  useComponentStylesLoaded('Listbox', [SWAN_STYLE_KEY_MAP.listbox, SWAN_STYLE_KEY_MAP.popover])
  const { children, className, disabled = false, fullWidth = false, size = 'standard', skin = 'standard' } = props
  const selectionProps = useSelectionProps(props)

  const classes = new Set(['swan-listbox'])
  if (size === 'mini') classes.add('swan-listbox-mini')
  if (fullWidth) classes.add('swan-listbox-full-width')
  if (className) classes.add(className)

  const filteredProps = filterProps(props, propKeysToRemove, processStyleProps(props, classes))

  return (
    <div {...filteredProps} ref={ref}>
      <CollectionContextProvider items={props.items} disabledKeys={props.disabledKeys}>
        <SwanListboxContextProvider fullWidth={fullWidth} skin={skin} disabled={disabled ?? false} selectionProps={selectionProps}>
          {children}
        </SwanListboxContextProvider>
      </CollectionContextProvider>
    </div>
  )
})
