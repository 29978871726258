import PropTypes, { InferProps } from 'prop-types'
import { Children, cloneElement, isValidElement } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

import { FloatingLabelContextProvider } from '~/react/contexts/internal/floating-label'

const propTypes = {
  /**
   * Whether or not the Listbox should expand to fill the entire width of its container.
   *
   * @default false
   */
  fullWidth: PropTypes.bool,
}

/**
 * @deprecated
 *
 * Use InputWithFloatingLabelProps instead
 */
export type ListboxWithFloatingLabelProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

const ListboxWithFloatingLabelInternal = renderWithRef<MinNativeRef, ListboxWithFloatingLabelProps>('ListboxWithFloatingLabel', propTypes, (props, ref) => {
  const { fullWidth = false, children, ...rest } = props

  const classNames = new Set(['swan-vanilla-ignore', 'swan-listbox-with-floating-label'])
  if (fullWidth) classNames.add(`swan-listbox-with-floating-label-full-width`)

  return (
    <FloatingLabelContextProvider>
      <RenderComp root="div" forwardedRef={ref} props={rest} classNames={classNames}>
        {Children.map(children, (child, index) => {
          if (index === 0 && isValidElement(child)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return cloneElement(child as unknown as any, {
              fullWidth: fullWidth ?? undefined,
            })
          }
          return child
        })}
      </RenderComp>
    </FloatingLabelContextProvider>
  )
})

/**
 *
 * @deprecated
 * Use the generic component InputWithFloatingLabel instead
 *
 * @subcomponent Listbox
 */

export const ListboxWithFloatingLabel = deprecatedComponent({
  description: 'Use the generic component InputWithFloatingLabel instead',
  displayName: 'ListboxWithFloatingLabel',
  root: ListboxWithFloatingLabelInternal,
})
