/**
 * Do not edit directly
 * Generated on Thu, 10 Apr 2025 07:57:16 GMT
 */

export const tokensRaw = {
  "SwanCompAccordionPaddingColorSwatchesStandard": "2px",
  "SwanCompAccordionPaddingColorSwatchesSuper": "6px",
  "SwanCompAlertBoxLegalColorBg": "#ffffff",
  "SwanCompAlertBoxLegalColorText": "#000000",
  "SwanCompAlertBoxLegalColorBorder": "#000000",
  "SwanCompAlertBoxLegalBorderWidth": "1px",
  "SwanCompAlertBoxLegalFontSize": "16px",
  "SwanCompAlertBoxLegalFontWeight": "400",
  "SwanCompAlertBoxLegalFontLineHeight": "1.5",
  "SwanCompAlertBoxToastMaxWidth": "700px",
  "SwanCompAnchorBarListScrollBarColorBorder": "rgba(0,0,0,0.17)",
  "SwanCompAvatarColorBorder": "rgba(0,0,0,0.09)",
  "SwanCompBannerColorBg": "#f3f3f3",
  "SwanCompBannerLeftHandNavigationColorBg": "#ffffff",
  "SwanCompBannerLeftHandNavigationDividerColorBorder": "#d3d3d3",
  "SwanCompBannerLeftHandNavigationFocus": "0 0 0 2px #ffffff, 0 0 0 4px #0C72A9",
  "SwanCompBannerInner": "16px",
  "SwanCompBannerActionSizeMinHeight": "40px",
  "SwanCompBannerImageContainerSizeMaxHeight": "300px",
  "SwanCompBannerSizeMaxWidth": "1920px",
  "SwanCompBannerSizeMinHeightStandard": "0px",
  "SwanCompBannerSizeMinHeightShort": "0px",
  "SwanCompBannerBoundedContentInnerHorizontal": "calc(16px - 8px)",
  "SwanCompBannerTextOffset": "0px",
  "SwanCompBannerButtonPrimaryColorBg": "#000000",
  "SwanCompBannerButtonPrimaryColorBgHover": "#505050",
  "SwanCompBannerButtonPrimaryColorBgActive": "#3a3a3a",
  "SwanCompBannerButtonPrimaryColorText": "#ffffff",
  "SwanCompBannerButtonPrimaryColorIcon": "#ffffff",
  "SwanCompBannerButtonSecondaryColorBg": "transparent",
  "SwanCompBannerButtonSecondaryColorBgHover": "rgba(0,0,0,0.05)",
  "SwanCompBannerButtonSecondaryColorBgActive": "rgba(0,0,0,0.09)",
  "SwanCompBannerButtonSecondaryColorBorder": "#838383",
  "SwanCompBannerButtonSecondaryColorBorderHover": "#6c6c6c",
  "SwanCompBannerButtonSecondaryColorBorderActive": "#505050",
  "SwanCompBaseTileCardSpaceInner": "16px",
  "SwanCompBaseTileOverlaySpaceInnerStandard": "12px",
  "SwanCompBaseTileOverlaySpaceInnerAction": "8px",
  "SwanCompBoundedContentSizeMaxWidth": "1920px",
  "SwanCompBoundedContentInnerHorizontal": "calc(16px - 8px)",
  "SwanCompBoundedContentInner": "0px calc(16px - 8px)",
  "SwanCompBreadcrumbsLinkInnerHorizontal": "8px",
  "SwanCompBreadcrumbsLinkInnerVertical": "4px",
  "SwanCompButtonPrimaryColorBg": "#6FD0F5",
  "SwanCompButtonPrimaryColorBgHover": "#48BBEB",
  "SwanCompButtonPrimaryColorBgActive": "#2BA8E0",
  "SwanCompButtonPrimaryColorText": "#000000",
  "SwanCompButtonPrimaryColorIcon": "#000000",
  "SwanCompButtonTertiaryColorBg": "transparent",
  "SwanCompButtonTertiaryColorBgHover": "rgba(0,0,0,0.05)",
  "SwanCompButtonTertiaryColorBgActive": "rgba(0,0,0,0.09)",
  "SwanCompButtonClearSelectionBorderRadius": "9999px",
  "SwanCompButtonDestructivePrimaryColorBg": "#CC3011",
  "SwanCompButtonDestructivePrimaryColorBgHover": "#AE2C11",
  "SwanCompButtonDestructivePrimaryColorBgActive": "#952911",
  "SwanCompButtonDestructivePrimaryColorText": "#ffffff",
  "SwanCompButtonDestructivePrimaryColorIcon": "#ffffff",
  "SwanCompButtonTexture": "inset 0 -1px 1px rgba(0,0,0,0.09)",
  "SwanCompButtonSpaceInnerHorizontal": "16px",
  "SwanCompButtonSpaceInnerVertical": "12px",
  "SwanCompCarouselPromoBarButtonOffset": "4px",
  "SwanCompCarouselButtonColorBg": "rgba(255,255,255,0.81)",
  "SwanCompCarouselButtonColorBgDisabled": "rgba(255,255,255,0.22)",
  "SwanCompCarouselButtonColorBorderDisabled": "rgba(0,0,0,0.05)",
  "SwanCompCarouselDotColorBg": "#838383",
  "SwanCompCarouselDotColorBgHover": "#6c6c6c",
  "SwanCompCarouselDotSizeDefault": "12px",
  "SwanCompCarouselDotSizeSelected": "24px",
  "SwanCompCarouselDotPadding": "6px",
  "SwanCompCheckboxSize": "20px",
  "SwanCompCheckboxSizeIcon": "16px",
  "SwanCompCheckboxFavoritePadding": "8px",
  "SwanCompColorSwatchesCrossbarColorBg": "rgba(0,0,0,0.49)",
  "SwanCompColorSwatchesBorderColor": "rgba(0,0,0,0.49)",
  "SwanCompColorSwatchesInnerSpace": "6px",
  "SwanCompColorSwatchesSizeStandard": "16px",
  "SwanCompColorSwatchesSizeSuper": "24px",
  "SwanCompColorSwatchesBorderWidth": "1px",
  "SwanCompColorSwatchesSelectedGutterStandard": "6px",
  "SwanCompCountSize": "20px",
  "SwanCompCountPaddingHorizontal": "6px",
  "SwanCompFileDropzoneSizeMinHeight": "180px",
  "SwanCompFileTileThumbProgressContainerMaxWidth": "250px",
  "SwanCompFileTileProgressContainerSizeHeight": "18px",
  "SwanCompFileTileListSizeThumbnail": "48px",
  "SwanCompHeadingSpaceBelowHeading": "8px",
  "SwanCompIconWhiteColor": "#ffffff",
  "SwanCompListboxPadding": "8px",
  "SwanCompListboxOptionPaddingHorizontal": "16px",
  "SwanCompListboxOptionPaddingVertical": "12px",
  "SwanCompLoadingShimmerColorBgColor": "#e6e6e6",
  "SwanCompLoadingShimmerColorBgColorAlt": "#d3d3d3",
  "SwanCompLoadingShimmerColorBgImage": "linear-gradient(90deg, #e6e6e6 0%, #d3d3d3 40%, #e6e6e6 80%, #e6e6e6 97%)",
  "SwanCompModalDialogBorderRadius": "0px",
  "SwanCompModalDialogPanelFooterElevation": "0px -16px 16px -16px rgba(0,0,0,0.17)",
  "SwanCompModalDialogInnerSpace": "24px",
  "SwanCompModalDialogPanelInnerSpace": "16px",
  "SwanCompModalDialogPanelSizeWidthCapped": "100%",
  "SwanCompModalDialogPanelSizeMaxWidthMenu": "calc(100vw - 24px)",
  "SwanCompModalDialogCloseButtonSize": "24px",
  "SwanCompModalDialogInnerHorizontal": "calc(16px - 8px)",
  "SwanCompModalDialogSizeMinHeight": "100%",
  "SwanCompModalDialogSizeMaxHeight": "100%",
  "SwanCompModalDialogSizeWidth": "100%",
  "SwanCompModalDialogSizeMinWidth": "250px",
  "SwanCompModalDialogSizeMaxWidth": "100%",
  "SwanCompModalDialogSizeMaxWidthGrow": "100%",
  "SwanCompModalDialogSizeHeaderMaxWidth": "calc(100% - (20px + 12px + 12px))",
  "SwanCompPopoverDropShadow": "0 2px 6px rgba(0,0,0,0.05)",
  "SwanCompPriceBarInnerGap": "0px",
  "SwanCompPriceBarMainFlexBasis": "100%",
  "SwanCompPriceBarMainGap": "0px",
  "SwanCompPriceBarMainJustifyContent": "space-between",
  "SwanCompPriceBarSegmentPadding": "12px",
  "SwanCompProgressBarColorBg": "#D2D3DA",
  "SwanCompProgressBarColorBorder": "#7D8394",
  "SwanCompPromoCodeLetterSpacing": "1px",
  "SwanCompRadioButtonSize": "20px",
  "SwanCompRadioButtonSizeDot": "8px",
  "SwanCompRangeEmptyColorBg": "#838383",
  "SwanCompRangeThumbHeight": "24px",
  "SwanCompRangeThumbWidth": "24px",
  "SwanCompRangeTrackHeight": "4px",
  "SwanCompRatingsStarsColorBgEmpty": "#D4620B",
  "SwanCompRatingsStarsColorBgFilled": "#FAA837",
  "SwanCompSearchInputPaddingVertical": "4px",
  "SwanCompSelectionSetTilesHeightStandard": "144px",
  "SwanCompSelectionSetTilesWidthStandard": "164px",
  "SwanCompSelectionSetTilesWidthVertical": "164px",
  "SwanCompSelectionSetTilesImageWidthStandard": "144px",
  "SwanCompSelectionSetTilesImageWidthMini": "60px",
  "SwanCompSelectionSetTilesImageHeightStandard": "144px",
  "SwanCompSelectionSetTilesImageHeightVertical": "88px",
  "SwanCompSpinnerSizeAnimationTiny": "20px",
  "SwanCompSpinnerSizeAnimationMini": "20px",
  "SwanCompSpinnerSizeAnimationStandard": "20px",
  "SwanCompSpinnerSizeAnimationSuper": "48px",
  "SwanCompStandardTileBlockEnd": "16px",
  "SwanCompStandardTileVerticalGap": "8px",
  "SwanCompStepIndicatorSizeIcon": "16px",
  "SwanCompTextInputFontSizeFocus": "16px",
  "SwanCompTextInputMiniPaddingVertical": "8px",
  "SwanCompThumbnailsHeroContentContainerMaxWidth": "1440px",
  "SwanCompThumbnailsHeroContentBorderRadius": "16px",
  "SwanCompThumbnailsHeroThumbnailButtonSize": "40px",
  "SwanCompThumbnailsHeroThumbnailButtonBackgroundColor": "rgba(255,255,255,0.71)",
  "SwanCompThumbnailsHeroThumbnailButtonBorderColor": "#000000",
  "SwanCompToggleSwitchHandleColorBg": "#000000",
  "SwanCompToggleSwitchHandleColorBgDisabled": "#9c9c9c",
  "SwanCompToggleSwitchSizeMiniHeight": "8px",
  "SwanCompToggleSwitchSizeStandardHeight": "16px",
  "SwanCompZoomControlsLabelSizeMinWidthStandard": "60px",
  "SwanCompZoomControlsMargin": "4px",
  "SwanBaseBorderRadius0": "0px",
  "SwanBaseBorderRadius100": "4px",
  "SwanBaseBorderRadius200": "8px",
  "SwanBaseBorderRadius300": "12px",
  "SwanBaseBorderRadius400": "16px",
  "SwanBaseBorderRadiusMax": "9999px",
  "SwanBaseBorderWidth0": "0px",
  "SwanBaseBorderWidth100": "1px",
  "SwanBaseBorderWidth200": "2px",
  "SwanBaseBorderWidth300": "3px",
  "SwanBaseBreakpointXsStart": "0px",
  "SwanBaseBreakpointXsEnd": "767px",
  "SwanBaseBreakpointSmStart": "768px",
  "SwanBaseBreakpointSmEnd": "1023px",
  "SwanBaseBreakpointMdStart": "1024px",
  "SwanBaseBreakpointMdEnd": "1439px",
  "SwanBaseBreakpointLgStart": "1440px",
  "SwanBaseBreakpointLgEnd": "1919px",
  "SwanBaseBreakpointXlStart": "1920px",
  "SwanBaseColorTransparent": "transparent",
  "SwanBaseColorBlack": "#000000",
  "SwanBaseColorBlackOpacity90": "rgba(0,0,0,0.77)",
  "SwanBaseColorBlackOpacity80": "rgba(0,0,0,0.69)",
  "SwanBaseColorBlackOpacity70": "rgba(0,0,0,0.58)",
  "SwanBaseColorBlackOpacity60": "rgba(0,0,0,0.49)",
  "SwanBaseColorBlackOpacity50": "rgba(0,0,0,0.39)",
  "SwanBaseColorBlackOpacity40": "rgba(0,0,0,0.26)",
  "SwanBaseColorBlackOpacity30": "rgba(0,0,0,0.17)",
  "SwanBaseColorBlackOpacity20": "rgba(0,0,0,0.09)",
  "SwanBaseColorBlackOpacity10": "rgba(0,0,0,0.05)",
  "SwanBaseColorGrey100": "#f3f3f3",
  "SwanBaseColorGrey200": "#e6e6e6",
  "SwanBaseColorGrey300": "#d3d3d3",
  "SwanBaseColorGrey400": "#c4c4c4",
  "SwanBaseColorGrey500": "#9c9c9c",
  "SwanBaseColorGrey600": "#838383",
  "SwanBaseColorGrey700": "#6c6c6c",
  "SwanBaseColorGrey800": "#505050",
  "SwanBaseColorGrey900": "#3a3a3a",
  "SwanBaseColorGrey950": "#1d1d1d",
  "SwanBaseColorWhite": "#ffffff",
  "SwanBaseColorWhiteOpacity90": "rgba(255,255,255,0.95)",
  "SwanBaseColorWhiteOpacity80": "rgba(255,255,255,0.89)",
  "SwanBaseColorWhiteOpacity70": "rgba(255,255,255,0.81)",
  "SwanBaseColorWhiteOpacity60": "rgba(255,255,255,0.71)",
  "SwanBaseColorWhiteOpacity50": "rgba(255,255,255,0.56)",
  "SwanBaseColorWhiteOpacity40": "rgba(255,255,255,0.45)",
  "SwanBaseColorWhiteOpacity30": "rgba(255,255,255,0.35)",
  "SwanBaseColorWhiteOpacity20": "rgba(255,255,255,0.22)",
  "SwanBaseColorWhiteOpacity10": "rgba(255,255,255,0.13)",
  "SwanBaseColorRed100": "#FFEEEB",
  "SwanBaseColorRed200": "#FFDFDB",
  "SwanBaseColorRed300": "#FFC6BF",
  "SwanBaseColorRed400": "#FFB1A5",
  "SwanBaseColorRed500": "#F5765F",
  "SwanBaseColorRed600": "#E84D2F",
  "SwanBaseColorRed700": "#CC3011",
  "SwanBaseColorRed800": "#952911",
  "SwanBaseColorRed900": "#6B2110",
  "SwanBaseColorYellow100": "#FFF1DE",
  "SwanBaseColorYellow200": "#FFE2BD",
  "SwanBaseColorYellow300": "#FFCA87",
  "SwanBaseColorYellow400": "#FAA837",
  "SwanBaseColorYellow500": "#E58325",
  "SwanBaseColorYellow600": "#D4620B",
  "SwanBaseColorYellow700": "#A94505",
  "SwanBaseColorYellow800": "#86390C",
  "SwanBaseColorYellow900": "#602B0D",
  "SwanBaseColorGreen100": "#E2F8E7",
  "SwanBaseColorGreen200": "#C8F0D1",
  "SwanBaseColorGreen300": "#A2E2B3",
  "SwanBaseColorGreen400": "#85D5A0",
  "SwanBaseColorGreen500": "#3EAF79",
  "SwanBaseColorGreen600": "#1B956B",
  "SwanBaseColorGreen700": "#117A5E",
  "SwanBaseColorGreen800": "#065B45",
  "SwanBaseColorGreen900": "#044232",
  "SwanBaseColorBlue100": "#E5F5FD",
  "SwanBaseColorBlue200": "#C8EBFB",
  "SwanBaseColorBlue300": "#9BDCF8",
  "SwanBaseColorBlue400": "#6FD0F5",
  "SwanBaseColorBlue500": "#2BA8E0",
  "SwanBaseColorBlue600": "#1C8BC3",
  "SwanBaseColorBlue700": "#0C72A9",
  "SwanBaseColorBlue800": "#005484",
  "SwanBaseColorBlue900": "#003D62",
  "SwanBaseColorNavy100": "#F2F3F4",
  "SwanBaseColorNavy200": "#E5E6EA",
  "SwanBaseColorNavy300": "#D2D3DA",
  "SwanBaseColorNavy400": "#C1C3CC",
  "SwanBaseColorNavy500": "#979CAA",
  "SwanBaseColorNavy600": "#7D8394",
  "SwanBaseColorNavy700": "#656B80",
  "SwanBaseColorNavy800": "#474F67",
  "SwanBaseColorNavy900": "#2F3A53",
  "SwanBaseFontFamilyPrimary": "Graphik, sans-serif",
  "SwanBaseFontFamilySecondary": "Tiempos, Georgia, times, serif",
  "SwanBaseFontSize100": "16px",
  "SwanBaseFontSize200": "18px",
  "SwanBaseFontSize300": "20px",
  "SwanBaseFontSize400": "24px",
  "SwanBaseFontSize500": "28px",
  "SwanBaseFontSize600": "32px",
  "SwanBaseFontSize700": "36px",
  "SwanBaseFontSize800": "42px",
  "SwanBaseFontSize900": "48px",
  "SwanBaseFontSize050": "12px",
  "SwanBaseFontSize075": "14px",
  "SwanBaseFontWeightNormal": "400",
  "SwanBaseFontWeightBold": "700",
  "SwanBaseFontLetterSpacing100": "0em",
  "SwanBaseFontLetterSpacing200": "0.005em",
  "SwanBaseFontLetterSpacing300": "0.01em",
  "SwanBaseFontLetterSpacing025": "-0.03em",
  "SwanBaseFontLetterSpacing050": "-0.02em",
  "SwanBaseFontLetterSpacing075": "-0.01em",
  "SwanBaseFontLineHeight100": 1.1,
  "SwanBaseFontLineHeight200": 1.2,
  "SwanBaseFontLineHeight300": 1.3,
  "SwanBaseFontLineHeight400": 1.4,
  "SwanBaseFontLineHeight500": 1.5,
  "SwanBaseFontLineHeight075": 0.9,
  "SwanBaseSpace0": "0px",
  "SwanBaseSpace100": "8px",
  "SwanBaseSpace150": "12px",
  "SwanBaseSpace200": "16px",
  "SwanBaseSpace300": "24px",
  "SwanBaseSpace400": "32px",
  "SwanBaseSpace550": "44px",
  "SwanBaseSpace700": "56px",
  "SwanBaseSpace900": "72px",
  "SwanBaseSpace1200": "96px",
  "SwanBaseSpace1600": "128px",
  "SwanBaseSpace025": "2px",
  "SwanBaseSpace050": "4px",
  "SwanBaseSpace075": "6px",
  "SwanSemBorderRadiusNone": "0px",
  "SwanSemBorderRadiusSubtle": "4px",
  "SwanSemBorderRadiusStandard": "8px",
  "SwanSemBorderRadiusStrong": "16px",
  "SwanSemBorderRadiusRounded": "9999px",
  "SwanSemBorderRadiusFocus": "4px",
  "SwanSemBorderRadiusAction": "8px",
  "SwanSemBorderRadiusInput": "8px",
  "SwanSemBorderRadiusContainer": "16px",
  "SwanSemBorderWidthNone": "0px",
  "SwanSemBorderWidthStandard": "1px",
  "SwanSemBorderWidthStrong": "2px",
  "SwanSemBorderWidthStronger": "3px",
  "SwanSemBorderWidthInput": "1px",
  "SwanSemBorderWidthAction": "1px",
  "SwanSemBorderWidthPartition": "1px",
  "SwanSemBorderWidthFloating": "1px",
  "SwanSemBorderWidthSelected": "2px",
  "SwanSemColorBgNone": "transparent",
  "SwanSemColorBgNoneHover": "rgba(0,0,0,0.05)",
  "SwanSemColorBgNoneActive": "rgba(0,0,0,0.09)",
  "SwanSemColorBgStandard": "#ffffff",
  "SwanSemColorBgHover": "#f3f3f3",
  "SwanSemColorBgActive": "#e6e6e6",
  "SwanSemColorBgStrong": "#f3f3f3",
  "SwanSemColorBgStrongHover": "#e6e6e6",
  "SwanSemColorBgStrongActive": "#d3d3d3",
  "SwanSemColorBgDisabled": "#e6e6e6",
  "SwanSemColorBgSelected": "#000000",
  "SwanSemColorBgSelectedHover": "#3a3a3a",
  "SwanSemColorBgSelectedActive": "#505050",
  "SwanSemColorBgSelectedAccent": "#6FD0F5",
  "SwanSemColorBgSelectedAccentHover": "#2BA8E0",
  "SwanSemColorBgSelectedAccentActive": "#1C8BC3",
  "SwanSemColorBgAccent": "#E5F5FD",
  "SwanSemColorBgAccentStrongPaired": "#6FD0F5",
  "SwanSemColorBgAccentStrongHoverPaired": "#48BBEB",
  "SwanSemColorBgAccentStrongActivePaired": "#2BA8E0",
  "SwanSemColorBgError": "#FFEEEB",
  "SwanSemColorBgErrorStrongPaired": "#CC3011",
  "SwanSemColorBgWarning": "#FFF1DE",
  "SwanSemColorBgWarningStrongPaired": "#FAA837",
  "SwanSemColorBgSuccess": "#E2F8E7",
  "SwanSemColorBgSuccessStrongPaired": "#117A5E",
  "SwanSemColorBgPromo": "#E2F8E7",
  "SwanSemColorBgPromoStrongPaired": "#117A5E",
  "SwanSemColorBgHelp": "#FFF1DE",
  "SwanSemColorBgHelpStrongPaired": "#FAA837",
  "SwanSemColorBgInfo": "#F2F3F4",
  "SwanSemColorBgInfoStrongPaired": "#2F3A53",
  "SwanSemColorBgAction": "#ffffff",
  "SwanSemColorBgActionHover": "#f3f3f3",
  "SwanSemColorBgActionActive": "#e6e6e6",
  "SwanSemColorBgControl": "#ffffff",
  "SwanSemColorBgControlHover": "#f3f3f3",
  "SwanSemColorBgControlActive": "#e6e6e6",
  "SwanSemColorBgInput": "#ffffff",
  "SwanSemColorBgContainer": "#ffffff",
  "SwanSemColorBgPage": "#ffffff",
  "SwanSemColorBgDestructive": "#FFEEEB",
  "SwanSemColorBgDestructiveStrongPaired": "#CC3011",
  "SwanSemColorBorderNone": "transparent",
  "SwanSemColorBorderNoneHover": "rgba(0,0,0,0.05)",
  "SwanSemColorBorderNoneActive": "rgba(0,0,0,0.09)",
  "SwanSemColorBorderStandard": "#d3d3d3",
  "SwanSemColorBorderHover": "#9c9c9c",
  "SwanSemColorBorderActive": "#838383",
  "SwanSemColorBorderStrong": "#838383",
  "SwanSemColorBorderStrongHover": "#6c6c6c",
  "SwanSemColorBorderStrongActive": "#505050",
  "SwanSemColorBorderDisabled": "#d3d3d3",
  "SwanSemColorBorderSelected": "#000000",
  "SwanSemColorBorderSelectedHover": "#505050",
  "SwanSemColorBorderSelectedActive": "#6c6c6c",
  "SwanSemColorBorderSelectedAccent": "#6FD0F5",
  "SwanSemColorBorderSelectedAccentHover": "#2BA8E0",
  "SwanSemColorBorderSelectedAccentActive": "#1C8BC3",
  "SwanSemColorBorderAccent": "#9BDCF8",
  "SwanSemColorBorderAccentStrongPaired": "#6FD0F5",
  "SwanSemColorBorderAccentStrongHoverPaired": "#48BBEB",
  "SwanSemColorBorderAccentStrongActivePaired": "#2BA8E0",
  "SwanSemColorBorderError": "#FFC6BF",
  "SwanSemColorBorderErrorStrongPaired": "#CC3011",
  "SwanSemColorBorderWarning": "#FFCA87",
  "SwanSemColorBorderWarningStrongPaired": "#FAA837",
  "SwanSemColorBorderSuccess": "#A2E2B3",
  "SwanSemColorBorderSuccessStrongPaired": "#117A5E",
  "SwanSemColorBorderPromo": "#A2E2B3",
  "SwanSemColorBorderPromoStrongPaired": "#117A5E",
  "SwanSemColorBorderHelp": "#FFCA87",
  "SwanSemColorBorderHelpStrongPaired": "#FAA837",
  "SwanSemColorBorderInfo": "#D2D3DA",
  "SwanSemColorBorderInfoStrongPaired": "#2F3A53",
  "SwanSemColorBorderAction": "rgba(0,0,0,0.17)",
  "SwanSemColorBorderActionHover": "rgba(0,0,0,0.17)",
  "SwanSemColorBorderActionActive": "rgba(0,0,0,0.17)",
  "SwanSemColorBorderControl": "#838383",
  "SwanSemColorBorderControlHover": "#6c6c6c",
  "SwanSemColorBorderControlActive": "#505050",
  "SwanSemColorBorderInput": "#838383",
  "SwanSemColorBorderInputHover": "#6c6c6c",
  "SwanSemColorBorderInputActive": "#505050",
  "SwanSemColorBorderInputError": "#CC3011",
  "SwanSemColorBorderContainer": "#d3d3d3",
  "SwanSemColorBorderPartition": "#d3d3d3",
  "SwanSemColorBorderDestructive": "#FFC6BF",
  "SwanSemColorBorderDestructiveStrongPaired": "#CC3011",
  "SwanSemColorBrandVistaBlue": "#6FD0F5",
  "SwanSemColorBrandMidnight": "#2F3A53",
  "SwanSemColorBrandWarmWhite": "#F6F6EC",
  "SwanSemColorIconStandard": "#000000",
  "SwanSemColorIconDisabled": "#9c9c9c",
  "SwanSemColorIconAccent": "#0C72A9",
  "SwanSemColorIconAccentStrongPaired": "#000000",
  "SwanSemColorIconError": "#CC3011",
  "SwanSemColorIconErrorStrongPaired": "#FFB1A5",
  "SwanSemColorIconWarning": "#A94505",
  "SwanSemColorIconWarningStrongPaired": "#000000",
  "SwanSemColorIconSuccess": "#117A5E",
  "SwanSemColorIconSuccessStrongPaired": "#85D5A0",
  "SwanSemColorIconPromo": "#117A5E",
  "SwanSemColorIconPromoStrongPaired": "#85D5A0",
  "SwanSemColorIconHelp": "#A94505",
  "SwanSemColorIconHelpStrongPaired": "#000000",
  "SwanSemColorIconInfo": "#656B80",
  "SwanSemColorIconInfoStrongPaired": "#C1C3CC",
  "SwanSemColorIconSubtle": "#6c6c6c",
  "SwanSemColorIconDestructive": "#CC3011",
  "SwanSemColorIconDestructiveStrongPaired": "#ffffff",
  "SwanSemColorTextStandard": "#000000",
  "SwanSemColorTextSubtle": "#6c6c6c",
  "SwanSemColorTextInput": "#000000",
  "SwanSemColorTextPlaceholder": "#6c6c6c",
  "SwanSemColorTextError": "#CC3011",
  "SwanSemColorTextErrorAlt": "#000000",
  "SwanSemColorTextErrorStrongPaired": "#ffffff",
  "SwanSemColorTextWarning": "#A94505",
  "SwanSemColorTextWarningAlt": "#000000",
  "SwanSemColorTextWarningStrongPaired": "#000000",
  "SwanSemColorTextDisabled": "#9c9c9c",
  "SwanSemColorTextPromo": "#117A5E",
  "SwanSemColorTextPromoAlt": "#000000",
  "SwanSemColorTextPromoStrongPaired": "#ffffff",
  "SwanSemColorTextHover": "rgba(0,0,0,0.77)",
  "SwanSemColorTextActive": "rgba(0,0,0,0.69)",
  "SwanSemColorTextAccent": "#0C72A9",
  "SwanSemColorTextAccentAlt": "#000000",
  "SwanSemColorTextAccentStrongPaired": "#000000",
  "SwanSemColorTextHelp": "#A94505",
  "SwanSemColorTextHelpAlt": "#000000",
  "SwanSemColorTextHelpStrongPaired": "#000000",
  "SwanSemColorTextSuccess": "#117A5E",
  "SwanSemColorTextSuccessAlt": "#000000",
  "SwanSemColorTextSuccessStrongPaired": "#ffffff",
  "SwanSemColorTextInfo": "#656B80",
  "SwanSemColorTextInfoAlt": "#000000",
  "SwanSemColorTextInfoStrongPaired": "#ffffff",
  "SwanSemColorTextLink": "inherit",
  "SwanSemColorTextLinkHover": "rgba(0,0,0,0.77)",
  "SwanSemColorTextLinkActive": "rgba(0,0,0,0.69)",
  "SwanSemColorTextDestructive": "#CC3011",
  "SwanSemColorTextDestructiveAlt": "#000000",
  "SwanSemColorTextDestructiveStrongPaired": "#ffffff",
  "SwanSemElevationNone": "none",
  "SwanSemElevationSubtle": "0 2px 4px 0 rgba(0,0,0,0.05)",
  "SwanSemElevationStandard": "0 2px 6px 0 rgba(0,0,0,0.05)",
  "SwanSemElevationStrong": "0 2px 8px 0 rgba(0,0,0,0.09)",
  "SwanSemElevationFloating": "0 2px 6px 0 rgba(0,0,0,0.05)",
  "SwanSemElevationOverlay": "0 2px 8px 0 rgba(0,0,0,0.09)",
  "SwanSemElevationScrollHorizontal": "0 0 12px 12px #ffffff",
  "SwanSemFocusColorInner": "#ffffff",
  "SwanSemFocusColorOuter": "#0C72A9",
  "SwanSemFocusStandard": "0 0 0 2px #ffffff, 0 0 0 4px #0C72A9",
  "SwanSemFocusInset": "inset 0 0 0 2px #ffffff, inset 0 0 0 4px #0C72A9",
  "SwanSemFocusTight": "0 0 0 2px #0C72A9",
  "SwanSemFontSizeX4large": "36px",
  "SwanSemFontSizeX3large": "28px",
  "SwanSemFontSizeX2large": "20px",
  "SwanSemFontSizeXlarge": "18px",
  "SwanSemFontSizeLarge": "16px",
  "SwanSemFontSizeStandard": "14px",
  "SwanSemFontSizeSmall": "12px",
  "SwanSemFontSizeXsmall": "12px",
  "SwanSemMqEqXl": "'only screen and (min-width: 1920px)'",
  "SwanSemMqEqLg": "'only screen and (min-width: 1440px) and (max-width: 1919px)'",
  "SwanSemMqEqMd": "'only screen and (min-width: 1024px) and (max-width: 1439px)'",
  "SwanSemMqEqSm": "'only screen and (min-width: 768px) and (max-width: 1023px)'",
  "SwanSemMqEqXs": "'only screen and (max-width: 767px)'",
  "SwanSemMqGtXl": "'only screen and (min-width: 1920px)'",
  "SwanSemMqGtLg": "'only screen and (min-width: 1920px)'",
  "SwanSemMqGtMd": "'only screen and (min-width: 1440px)'",
  "SwanSemMqGtSm": "'only screen and (min-width: 1024px)'",
  "SwanSemMqGtXs": "'only screen and (min-width: 768px)'",
  "SwanSemMqGteXl": "'only screen and (min-width: 1920px)'",
  "SwanSemMqGteLg": "'only screen and (min-width: 1440px)'",
  "SwanSemMqGteMd": "'only screen and (min-width: 1024px)'",
  "SwanSemMqGteSm": "'only screen and (min-width: 768px)'",
  "SwanSemMqGteXs": "'only screen and (min-width: 0px)'",
  "SwanSemMqLtXl": "'only screen and (max-width: 1919px)'",
  "SwanSemMqLtLg": "'only screen and (max-width: 1439px)'",
  "SwanSemMqLtMd": "'only screen and (max-width: 1023px)'",
  "SwanSemMqLtSm": "'only screen and (max-width: 767px)'",
  "SwanSemMqLteLg": "'only screen and (max-width: 1919px)'",
  "SwanSemMqLteMd": "'only screen and (max-width: 1439px)'",
  "SwanSemMqLteSm": "'only screen and (max-width: 1023px)'",
  "SwanSemMqLteXs": "'only screen and (max-width: 767px)'",
  "SwanSemSizeIconStandard": "20px",
  "SwanSemSizeIconDynamic": "1lh",
  "SwanSemSizeIconStrokeWidth": "2px",
  "SwanSemSizeIconSmall": "16px",
  "SwanSemSizeIllustrationStandard": "104px",
  "SwanSemSizeIllustrationLarge": "168px",
  "SwanSemSizeActionAndInputInnerHeight": "20px",
  "SwanSemSizeActionAndInputInnerMinWidth": "20px",
  "SwanSemSizeClickTargetMinHeight": "24px",
  "SwanSemSizeClickTargetMinWidth": "24px",
  "SwanSemSpace1": "2px",
  "SwanSemSpace2": "4px",
  "SwanSemSpace3": "8px",
  "SwanSemSpace4": "12px",
  "SwanSemSpace5": "16px",
  "SwanSemSpace6": "24px",
  "SwanSemSpace7": "32px",
  "SwanSemSpace8": "44px",
  "SwanSemSpace9": "56px",
  "SwanSemSpace10": "72px",
  "SwanSemSpace11": "96px",
  "SwanSemSpace12": "128px",
  "SwanSemSpaceNone": "0px",
  "SwanSemSpaceGutterStandard": "8px",
  "SwanSemSpaceGutterTight": "2px",
  "SwanSemSpaceInputPaddingHorizontal": "16px",
  "SwanSemSpaceInputPaddingVertical": "12px",
  "SwanSemSpaceBetweenSections": "44px",
  "SwanSemSpaceBetweenSubsections": "24px",
  "SwanSemSpaceBetweenActions": "8px",
  "SwanSemSpaceBetweenIconAndText": "8px",
  "SwanSemSpaceToActions": "16px",
  "SwanSemZIndexRaised": 1,
  "SwanSemZIndexLinkCovering": 5,
  "SwanSemZIndexOverlay": 10,
  "SwanSemZIndexFloating": 100000,
  "SwanSemZIndexOverTheTop": 1000000,
  "SwanInternalColorBgWarmWhite": "#F6F6EC",
  "SwanInternalColorBgMidnight": "#ffffff"
};
