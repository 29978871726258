import PropTypes, { InferProps } from 'prop-types'
import { useRef } from 'react'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { useDragAndDrop } from '~/react/components/file-dropzone/file-dropzone.hooks'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { Icon } from '~/react/components/icon'
import { Typography } from '~/react/components/typography'

import { assignRefs } from '~/core'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * The text for below the input button when in default state i.e. "or drag and drop here".
   */
  note: PropTypes.string.isRequired,
  /**
   * The text for when a file is dragged onto the drop zone i.e. Drop your files here.
   */
  dropNote: PropTypes.string.isRequired,
  /**
   * The error message for when multiple files are dropped when single file upload only i.e. You can only upload one file
   */
  multiFileErrorMessage: PropTypes.string.isRequired,
}

export type FileDropzoneProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const FileDropzone = renderWithRef<MinNativeRef, FileDropzoneProps>('FileDropzone', propTypes, (props, ref) => {
  useComponentStylesLoaded('FileDropzone', SWAN_STYLE_KEY_MAP.fileDropzone)

  const { children, note, dropNote, multiFileErrorMessage, ...rest } = props

  const classNames = new Set(['swan-file-dropzone'])

  const fileDropzoneRef = useRef<HTMLDivElement | null>(null)

  const { isDragging, isMultiDropError } = useDragAndDrop(fileDropzoneRef)
  if (isDragging) classNames.add('swan-file-dropzone-on-drag')
  if (isMultiDropError) classNames.add('swan-file-dropzone-has-multi-file-error')

  const combinedRefs = assignRefs(ref, fileDropzoneRef)

  return (
    <RenderComp classNames={classNames} root="div" forwardedRef={combinedRefs} props={rest}>
      {children}
      <Typography className="swan-file-dropzone-note">{note}</Typography>
      <div className="swan-file-dropzone-drop-note">
        <Icon iconType="add" />
        <Typography fontSkin="body-standard-bold">{dropNote}</Typography>
      </div>
      <div className="swan-file-dropzone-error-message">
        <Icon iconType="error" />
        <Typography fontSkin="body-standard-bold">{multiFileErrorMessage}</Typography>
      </div>
    </RenderComp>
  )
})
