import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

import { FloatingLabelContextProvider } from '~/react/contexts/internal/floating-label'

/**
 * @deprecated
 *
 * Use InputWithFloatingLabelProps instead
 */
export type TextInputWithFloatingLabelProps = CoreProps<JSX.IntrinsicElements['div']>

const TextInputWithFloatingLabelInternal = renderWithRef<MinNativeRef, TextInputWithFloatingLabelProps>('TextInputWithFloatingLabel', null, (props, ref) => {
  const { children } = props
  const classes = ['swan-vanilla-ignore', 'swan-input-with-floating-label']
  return (
    <FloatingLabelContextProvider>
      <RenderComp root="div" forwardedRef={ref} props={props} classNames={classes}>
        {children}
      </RenderComp>
    </FloatingLabelContextProvider>
  )
})

/**
 * @deprecated
 * This is deprecated. Use the generic component InputWithFloatingLabel instead
 *
 * @subcomponent TextInput
 */
export const TextInputWithFloatingLabel = deprecatedComponent({
  description: 'Use the generic component InputWithFloatingLabel instead',
  displayName: 'TextInputWithFloatingLabel',
  root: TextInputWithFloatingLabelInternal,
})
