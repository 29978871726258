import { useEffect } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'

import { useId } from '~/react/hooks/use-id'

import { useNonNullishFormFieldContext } from './form.context'

export type FormErrorProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

/**
 * @subcomponent StandardForm
 */
export const FormError = renderWithRef<MinNativeRef, FormErrorProps>('FormError', null, ({ children, ...props }, ref) => {
  const id = useId(props.id)
  const { setErrorIds } = useNonNullishFormFieldContext()

  // add the id to the collection on load, and remove on unmount
  useEffect(() => {
    if (id) {
      setErrorIds(s => new Set([...s, id]))
    }
    return () => {
      if (id) {
        setErrorIds(s => {
          const newSet = new Set([...s])
          newSet.delete(id)
          return newSet
        })
      }
    }
  }, [id, setErrorIds])

  return (
    <RenderComp root="p" forwardedRef={ref} props={{ ...props, id }} classNames={['swan-input-group-error-text']}>
      <Icon iconType="error" skin="error" alt="" />
      {children}
    </RenderComp>
  )
})
