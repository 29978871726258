import { createContext, PropsWithoutRef, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type FloatingLabelContextValue = {
  inputId: string | undefined
  setInputId: (inputId: string | undefined) => void
}

export type FloatingLabelProps = {
  id?: string
  className?: string
  placeholder?: string
}

export const FloatingLabelContext = createContext<FloatingLabelContextValue | null | undefined>(null)

export const useFloatingLabelContext = () => useContext(FloatingLabelContext)

export const useNonNullishFloatingLabelContext = () => useNonNullishContext(FloatingLabelContext)

export function FloatingLabelContextProvider({ children }: { children: ReactNode }) {
  const [inputId, setInputId] = useState<string>()

  const value = useMemo(
    () => ({
      inputId,
      setInputId,
    }),
    [inputId, setInputId],
  )

  return <FloatingLabelContext.Provider value={value}>{children}</FloatingLabelContext.Provider>
}

/**
 * Used to store the id of the form element in context.
 * Conditionally, add required props to the form input element when the input is in a floating label context.
 */
export function useFloatingLabelFormElement<P extends PropsWithoutRef<FloatingLabelProps>>(props: P) {
  const { id } = props

  const floatingLabelContext = useFloatingLabelContext()
  const setInputId = floatingLabelContext?.setInputId

  useEffect(() => {
    if (setInputId === undefined) {
      return
    }

    setInputId(id)

    return () => {
      setInputId(undefined)
    }
  }, [id, setInputId])

  return props
}

// Text Inputs (incl Combobox) need a placeholder attribute, so they get a wrapper function that adds it
export function useFloatingLabelTextInputElement<P extends PropsWithoutRef<FloatingLabelProps>>(props: P) {
  const floatingLabelContext = useFloatingLabelContext()

  const floatingProps = { ...props }

  if (floatingLabelContext) {
    /*
      In a floating label context:
      The CSS needs placeholder to be a non-empty string, so if it's not declared, set it to a space character.
      
      The tricky part is the screen readers will read the label content AND the placeholder content,
      and there's no way to "hide" the placeholder content from assistive tech.
      So we need to put something in there that's not a terrible experience for screen reader users.
      We _could_ just copy the label text into the placeholder, but screen readers would read the same text twice, which is annoying.
      And it can't be a word/phrase like "ignore this" because we need to support localization.
      So we're having it default to a space character until we have a better idea.
    */
    floatingProps.placeholder = props.placeholder ?? ' '
  }

  return useFloatingLabelFormElement(floatingProps)
}

/**
 * Generates the html attributes to apply to the label element to associate it with the form element
 */
export function useFloatingLabelProps() {
  const { inputId } = useNonNullishFloatingLabelContext()
  return {
    htmlFor: inputId,
  }
}
