import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

import { useFloatingLabelProps } from '~/react/contexts/internal/floating-label/'

/**
 * @deprecated
 *
 * Use FloatingLabelProps instead
 */
export type DropdownFloatingLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef>

const DropdownFloatingLabelInternal = renderWithRef<MinNativeRef, DropdownFloatingLabelProps>('DropdownFloatingLabel', null, ({ children, ...props }, ref) => {
  const labelProps = useFloatingLabelProps()

  return (
    <RenderComp root="label" classNames={['swan-dropdown-floating-label']} forwardedRef={ref} props={{ ...props, ...labelProps }}>
      {children}
    </RenderComp>
  )
})

/**
 *
 * @deprecated
 * Use the generic component FloatingLabel instead
 *
 * @subcomponent Dropdown
 */
export const DropdownFloatingLabel = deprecatedComponent({
  description: 'Use the generic component FloatingLabel instead',
  displayName: 'DropdownFloatingLabel',
  root: DropdownFloatingLabelInternal,
})
