import { tokens } from '~/core/tokens'
import { SpacePropType, StyleBreakpoints, StylePropsKeys, StyleSpace } from '~/core/types/swan-style.types'

export const stylePropertyPrefixes: Partial<Record<StylePropsKeys, string>> = {
  margin: 'm',
  marginTop: 'mt',
  marginRight: 'mr',
  marginBottom: 'mb',
  marginLeft: 'ml',
  marginX: 'mx',
  marginY: 'my',
  padding: 'p',
  paddingTop: 'pt',
  paddingRight: 'pr',
  paddingBottom: 'pb',
  paddingLeft: 'pl',
  paddingX: 'px',
  paddingY: 'py',
  backgroundColor: 'bgc',
  visuallyHidden: 'visually-hidden',
  textAlign: 'text-align',
  textColor: 'text-color',
  textAllCaps: 'text-all-caps',
  fontWeight: 'font-weight',
  fontSize: 'text',
  fontFamily: 'font',
  fontSkin: 'font-skin',
  standardMode: 'standard-mode',
  darkMode: 'dark-mode',
  compactMode: 'compact-mode',
  loadingShimmer: 'loading-shimmer',
}

/**
 * An object requiring every single key in SwanStyleProps
 *
 * This allows us to use `Object.keys` to get a type-safe array of all of the core style props
 */
const allStyleProps: Required<Record<StylePropsKeys, false>> = {
  margin: false,
  m: false,
  marginTop: false,
  mt: false,
  marginRight: false,
  mr: false,
  marginBottom: false,
  mb: false,
  marginLeft: false,
  ml: false,
  marginX: false,
  mx: false,
  marginY: false,
  my: false,
  padding: false,
  p: false,
  paddingTop: false,
  pt: false,
  paddingRight: false,
  pr: false,
  paddingBottom: false,
  pb: false,
  paddingLeft: false,
  pl: false,
  paddingX: false,
  px: false,
  paddingY: false,
  py: false,
  backgroundColor: false,
  bgc: false,
  textAlign: false,
  textAllCaps: false,
  textColor: false,
  fontWeight: false,
  fontSize: false,
  fontFamily: false,
  fontSkin: false,
  display: false,
  standardMode: false,
  darkMode: false,
  compactMode: false,
  overflow: false,
  visuallyHidden: false,
  loadingShimmer: false,
}

export const styleValidProperties = new Set(Object.keys(allStyleProps) as StylePropsKeys[])

export const responsiveModifierSet = new Set<StyleBreakpoints>(['xs', 'sm', 'md', 'lg', 'xl'])

export function isValidStyleProperty(key: string): key is StylePropsKeys {
  return styleValidProperties.has(key as StylePropsKeys)
}

export function isResponsiveModifier(key: string): key is StyleBreakpoints {
  return responsiveModifierSet.has(key as StyleBreakpoints)
}

export function getKey(key: string): string | null {
  if (isValidStyleProperty(key)) {
    return stylePropertyPrefixes[key] || key
  }
  return null
}

export const swanStyleObjectFitValues = ['cover', 'contain', 'none', 'unset', 'fill'] as const
export function swanStyleObjectFit(value: string | null | undefined) {
  if (!value) return null
  return `swan-image-fit-${value}`
}

export const spaceTokensMap = SpacePropType.reduce(
  (acc, space) => {
    const spaceToken = `SwanSemSpace${space}` as const
    if (spaceToken in tokens && spaceToken !== 'SwanSemSpace0') {
      return {
        ...acc,
        [space]: tokens[spaceToken],
      }
    }

    return acc
  },
  {
    0: tokens.SwanSemSpaceNone,
  } as Record<Exclude<StyleSpace, string>, string>,
)
