import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'

export type SelectionSetErrorProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

/**
 * @subcomponent SelectionSet
 */
export const SelectionSetError = renderWithRef<MinNativeRef, SelectionSetErrorProps>('SelectionSetError', null, (props, ref) => (
  <RenderComp root="p" forwardedRef={ref} classNames={['swan-selection-set-error']} props={props}>
    <Icon iconType="error" skin="error" alt="" />
    {props.children}
  </RenderComp>
))
