import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

import { FloatingLabelContextProvider } from '~/react/contexts/internal/floating-label'

const propTypes = {
  /**
   * Whether or not to have the Dropdown expand to fill its container's width
   * @default false
   */
  fullWidth: PropTypes.bool,
}

/**
 * @deprecated
 *
 * Use InputWithFloatingLabelProps instead
 */
export type DropdownWithFloatingLabelProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

const DropdownWithFloatingLabelInternal = renderWithRef<MinNativeRef, DropdownWithFloatingLabelProps>('DropdownWithFloatingLabel', propTypes, (props, ref) => {
  const { fullWidth = false, children, ...rest } = props

  const classNames = new Set(['swan-vanilla-ignore', 'swan-dropdown-with-floating-label'])
  if (fullWidth) classNames.add(`swan-dropdown-with-floating-label-full-width`)

  return (
    <FloatingLabelContextProvider>
      <RenderComp root="div" forwardedRef={ref} props={rest} classNames={classNames}>
        {children}
      </RenderComp>
    </FloatingLabelContextProvider>
  )
})

/**
 *
 * @deprecated
 * Use the generic component InputWithFloatingLabel instead
 *
 * @subcomponent Dropdown
 */
export const DropdownWithFloatingLabel = deprecatedComponent({
  description: 'Use the generic component InputWithFloatingLabel instead',
  displayName: 'DropdownWithFloatingLabel',
  root: DropdownWithFloatingLabelInternal,
})
